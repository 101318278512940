@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@500;600&family=Montserrat:wght@400;500;600&family=Nunito:wght@600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    box-sizing: border-box;
}
#fp-table{
    display: flex;
    justify-content: start;
}
.fp-watermark{
    display: none;
}
.fp-overflow{
    height: 100vh;
}




#fp-nav ul li a span{
    background-color: transparent;
}
.fp-right ul li {
    border: 1px solid #000;
    border-radius: 50%;
}

#fp-nav ul li a span{
    border: none;
}
#fp-nav ul li{
    width: 9px;
    height: 9px;
}

#fp-nav ul li a.active span{
    width: 12px;
    height: 12px;
    background-color: #242A3A;
}

#fullpage-wrapper .section:first-child #fp-nav ul li {
    border: 1px solid #e7e3e3;
    background-color: #fff;
}
#fullpage-wrapper .section:first-child #fp-nav ul li a.active span{
  background-color: #fff;
}



/* Main carousel  */
.slick-next, .slick-prev{
    top:114%;
    width: 60px;
    height: 60px;
    padding: 16px;
    border: 3px solid #fff;
    border-radius: 50%;
    transition: .3s ease-in-out all;
}

.slick-next:hover, .slick-prev:hover{
    border: 3px solid #7000FF
}

.slick-next:hover path, .slick-prev:hover path{
    stroke: #7000FF;
}

.slick-prev{
    left: calc(100% - 133px);
}
.slick-next{
    right: 0;
}

/* @media screen and (max-width:1440px) {
    .slick-prev{
        left: 88.5%
    }
    .slick-next{
        right: 0;
    }
} */


/* @media screen and (max-width:1280px) {
    .slick-prev{
        left: 86%
    }
    .slick-next{
        right: 0;
    }
} */

@media screen and (max-width:1024px) {
    .slick-next, .slick-prev{
        top: 116%;
        width: 45px;
        height: 45px;
        padding: 12px;
    }
    /* .slick-prev{
        left: 84%
    }
    .slick-next{
        right: 0;
    } */
}

@media screen and (max-width:1024px) {
    .slick-prev{
        left: calc(100% - 100px);
    }
    .slick-next{
        right: 0;
    }
}

@media screen and (max-width:640px) {
    .slick-next, .slick-prev{
        top: 109%;
    }
}

@media screen and (max-width:530px) {
    .slick-next, .slick-prev{
        top: 110%;
        width: 40px;
        height: 40px;
        padding: 8px;
    }
    /* .slick-prev{
        left: 80%
    }
    .slick-next{
        right: 2%;
    } */
}

/* @media screen and (max-width:440px) {
    .slick-prev{
        left: 76%
    }
    .slick-next{
        right: 2%;
    }
} */
/* 
.header-slider{
    height: 350px;
} */
/* .header-slider .slick-list{
    margin: 0 -10px;
} */
/* .header-slider .slick-slide{
    margin: 0 10px;
} */



.slick-recom-prev, .slick-recom-next{
    position: absolute;
    left: -12%;
}

.slick-recom-next{
    top: 15%;
}

.slick-recom-prev{
    top: 28%;
}

.slick-recom-next:hover, .slick-recom-prev:hover{
    border: 3px solid #4B5369
}

.slick-recom-next:hover path, .slick-recom-prev:hover path{
    stroke: #4B5369;
}

.recommen-slider .slick-slide {
    min-width: 200px;
    max-width: 289px;
    margin: 0 5px;
}
.recommen-slider .slick-list {
  height: 590px;
  margin: 0 -10px;
}

.recommen-slider .slick-track{
    max-height: 600px;
}

.recommen-slider .slick-dots{
    padding: 16px 0;
    bottom: 0;
}

.recommen-slider .slick-dots li button::before{
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #4B5369;
}
.recommen-slider .slick-dots li.slick-active button:before{
    width: 12px;
    height: 12px;
    background-color: #7000FF;
}

.about-slider .slick-list{
    margin: 0 -10px;
}

.about-slider .slick-slide{
    margin: 0 10px;
}

.slick-about-next, .slick-about-prev{
    display: none;
}

.about-slider .slick-dots li button:before{
    font-size: 14px;
}

.about-slider .slick-dots li.slick-active button:before{
    font-size: 16px;
    color: #7000FF;   
}

.begin .slick-slide{
    margin: 0 10px;
}

.slick-begin-next path, .slick-begin-prev path{
    stroke: #000;
}

.slick-begin-next:hover, .slick-begin-prev:hover{
    border: 3px solid #4B5369;
}

.slick-begin-prev, .slick-begin-next{
    position: absolute;
    left: -8%;
    border: 3px solid #D9D9D9;
    z-index: 60;
}

.slick-begin-prev{
    top: 640px;
}

.slick-begin-next{
    top:570px
}

@media screen and (max-width:1440px) {
    .slick-begin-prev{
        top: 640px;
    }
    
    .slick-begin-next{
        top:560px
    }

}

@media screen and (max-width:1280px) {
    .slick-begin-prev, .slick-begin-next{
        position: absolute;
        left: -10%;
        border: 3px solid #D9D9D9;
        z-index: 60;
    }
}

@media screen and (max-width:1024px) {
    .slick-begin-prev, .slick-begin-next{
        display: none;
    }
}







.bg-gr1{
   opacity: 0.68; 
   background: linear-gradient(180deg, #4B3535 0%, #000 32.16%, rgba(18, 18, 18, 0.8) 59.07%, #040404 100%);
   mix-blend-mode: multiply; 
}

.bg-gr2{
    opacity: 0.68;
    background: linear-gradient(180deg, rgba(75, 53, 53, 0.00) 44.35%, #000 62.25%, rgba(0, 0, 0, 0.80) 77.22%, #040404 100%);
    mix-blend-mode: multiply;
}

.bg-gr3{
    background: linear-gradient(180deg, rgba(75, 53, 53, 0.00) 44.35%, #000 62.25%, rgba(0, 0, 0, 0.80) 77.22%, #000 100%);
    opacity: 0.68;
    mix-blend-mode: multiply;
}

.heart{
    background-color: rgba(255, 255, 255, 0.71);
    opacity: 0.8;
}

.basket span{
    display: inline-block;
    position: absolute;
    color: white;
    top: -14px;
    right: -14px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    font-size: 10px;
    background-color: #7000FF;
    /* overflow: hidden; */
}

.recommen-slider::before{
    content: '';
    position: absolute;
    left: -320px;
    top: -77px;
    width: 482px;
    height: 720px;
    background-color: #242A3A;
}

@media screen and (max-width:1440px) {
    .recommen-slider::before{
        width: 460px;
        left: -300px;
    }
}

@media screen and (max-width:1280px) {
    .recommen-slider::before{
        width: 420px;
        left: -240px;
    }
}

@media screen and (max-width:1024px) {
    .recommen-slider::before{
        display: none;
    }
    .slick-recom-prev, .slick-recom-next{
        margin: 0;
        display: none;
    }
}

.card-down{
    transition: transform 0.5s ease-in-out ;
}
.card:hover .card-down{
    transform: translateY(0);
}

.mobile{
    padding: 5px;
    width: 38px;
    height: 38px;
    border: 3px solid #7000FF;
    border-radius: 50%;
}

.location-slider .slick-list{
    margin: 0 -5px;
}
.location-slider .slick-slide {
    min-width: 200px;
    max-width: 300px;
    margin: 0 5px;
}


.scale-up-ver-top {
	-webkit-animation: scale-up-ver-top 0.4s ease-in-out both;
	        animation: scale-up-ver-top 0.4s ease-in-out both;
}

 @-webkit-keyframes scale-up-ver-top {
    0% {
      -webkit-transform: scaleY(0.4);
              transform: scaleY(0.4);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
  }
  @keyframes scale-up-ver-top {
    0% {
      -webkit-transform: scaleY(0.4);
              transform: scaleY(0.4);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
  }

.img_anim {
	-webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-100px);
              transform: translateX(-100px);
    }
  }
  @keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-100px);
              transform: translateX(-100px);
    }
  }
  
.google-map{
    filter: grayscale(100%) invert(92%) contrast(83%);
}

.slide-fwd-center {
	-webkit-animation: slide-fwd-center 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-fwd-center 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-fwd-center {
    0% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
    }
    100% {
      -webkit-transform: translateZ(160px);
              transform: translateZ(160px);
    }
  }
  @keyframes slide-fwd-center {
    0% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
    }
    100% {
      -webkit-transform: translateZ(160px);
              transform: translateZ(160px);
    }
  }
  
  
/* 1280 px  */
/* 
@media (max-width: 1280px) {
    .slick-prev{
        left: 86%
    }
    .slick-next{
        right: 0;
    }
  } */